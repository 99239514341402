/* eslint-env browser */
import './polyfills';

import $ from 'jquery';
import log from 'loglevel';
import { fetchWithCredentials } from '@universityofwarwick/serverpipe';
import * as flexiPicker from './flexi-picker';
import * as locationPicker from './location-picker';

/**
 * Attach handlers to all elements inside $scope. All jQuery selects
 * must be scoped to $scope, and you should only call bindTo on content
 * that you know has not had handlers attached already (either a whole page
 * that's just been loaded, or a piece of HTML you've just loaded into the
 * document dynamically)
 */
export function bindTo($scope) {
  $('[data-toggle="popover"]', $scope).popover();
  $('[data-toggle="tooltip"]', $scope).tooltip();

  flexiPicker.bindTo($scope);
  locationPicker.bindTo($scope);

  $('.js-update-space-indicator', $scope).on('click', function updateTestState(e) {
    e.preventDefault();
    const $target = $(this);
    const $group = $target.closest('.btn-group');
    const $button = $group.find('button');
    const $li = $target.closest('li');
    const $result = $group.find('.result-container');
    const value = $li.data('value');
    const contextClass = $li.data('contextClass');
    const text = $li.text();
    const spaceId = $target.closest('.js-space-indicator').data('spaceId');

    const formData = new FormData();
    formData.append('indicator', value);

    /**
     * @param {Response} response
     */
    function handleResponse(response) {
      if (!response.ok) throw new Error(`Unsuccessful response (${response.status})`);

      // Update UI to reflect new state
      $button.text(text)
        .append('<span class="caret"></span>')
        .removeClass((i, className) => className.split(/\s+/).filter((c) => c !== 'btn-lg' && c.startsWith('btn-')).join(' '))
        .addClass(`btn-${contextClass}`);
      $result.html('&nbsp;<i class="fa fa-check text-success" aria-hidden="true"></i>');
    }

    /**
     * @param {Error} error
     */
    // eslint-disable-next-line no-unused-vars
    function handleError(error) {
      $group.find('.result-container').html('<span class="text-danger">Error updating state</span>');
    }

    fetchWithCredentials(`/admin/${spaceId}/update-indicator`, {
      method: 'POST',
      body: formData,
    }).then(handleResponse).catch(handleError);
  });

  $('[data-refresh-url][data-refresh-rate]', $scope).each((i, el) => {
    let $container = $(el);
    const url = $container.data('refreshUrl');
    const rate = $container.data('refreshRate');

    /**
     * @param {Error} error
     */
    // eslint-disable-next-line no-unused-vars
    function handleError(error) {
      log.error(`Error updating state: ${error}`);
      $container.append('<p class="text-danger">Error updating state</p>');
    }

    /**
     * @param {Response} response
     */
    function handleResponse(response) {
      if (!response.ok) throw new Error(`Unsuccessful response (${response.status})`);

      return response.text().then((t) => {
        const $newContainer = $(t);
        $container.replaceWith($newContainer);
        $container = $newContainer;
      }).catch(handleError);
    }

    function refresh() {
      fetchWithCredentials(url, { method: 'GET' })
        .then(handleResponse).catch(handleError);
    }

    setInterval(refresh, rate * 1000);
  });
}

$(() => {
  $.fn.collapse.Constructor.TRANSITION_DURATION = 0;
  const $html = $('html');

  // Apply to all content loaded non-AJAXically
  bindTo($('#main'));

  // Any selectors below should only be for things that we know won't be inserted into the
  // page after DOM ready.

  // Dismiss popovers when clicking away
  function closePopover($popover) {
    const $creator = $popover.data('creator');
    if ($creator) {
      $creator.popover('hide');
    }
  }

  $html
    .on('shown.bs.popover', (e) => {
      const $po = $(e.target).popover().data('bs.popover').tip();
      $po.data('creator', $(e.target));
    })
    .on('click.popoverDismiss', (e) => {
      const $target = $(e.target);

      // if clicking anywhere other than the popover itself
      if ($target.closest('.popover').length === 0 && $(e.target).closest('.has-popover').length === 0) {
        $('.popover').each((i, popover) => closePopover($(popover)));
      } else if ($target.closest('.close').length > 0) {
        closePopover($target.closest('.popover'));
      }
    })
    .on('keyup.popoverDismiss', (e) => {
      const key = e.which || e.keyCode;
      if (key === 27) {
        $('.popover').each((i, popover) => closePopover($(popover)));
      }
    });
});
