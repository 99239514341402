import $ from 'jquery';
import log from 'loglevel';
import _ from 'lodash-es';
import '@universityofwarwick/bootstrap-3-typeahead';
import RichResultField from './rich-result-field';

const campusMapAuthorizationHeader = 'Token 3a08c5091e5e477faa6ea90e4ae3e6c3';

export default class LocationPicker {
  constructor(input, {
    selectOnBlur = true,
  } = {}) {
    const self = this;
    const $element = $(input);

    // Might have manually wired this element up with FlexiPicker,
    // but add the class for CSS style purposes.
    if (!$element.hasClass('flexi-picker')) {
      $element.addClass('flexi-picker');
    }

    // Disable browser autocomplete dropdowns, it gets in the way.
    $element.attr('autocomplete', 'off');

    this.richResultField = new RichResultField(input);
    this.currentQuery = null;
    this.doSearch = this.doSearch.bind(this);

    $element.typeahead({
      source: (query, callback) => {
        this.doSearch(query, {}, callback);
      },
      delay: 200,
      // appendTo: $('body'),
      matcher: () => true, // All data received from the server matches the query
      displayText: (item) => {
        return `
          <div class="flexi-picker-result">
            <span class="title">${_.escape(item.title)}</span>
            <div class="description">
              ${(typeof (item.description) !== 'undefined' ? _.escape(item.description) : '')}
            </div>
          </div>`;
      },
      highlighter: (html) => html,
      changeInputOnMove: false,
      selectOnBlur,
      afterSelect: (item) => {
        const description = (
          typeof (item.description) !== 'undefined' ? ` (${_.escape(item.description)})` : ''
        );
        const text = `${item.title}${description}`;
        self.richResultField.store(item.id, text);
        $element.data('item', item);
      },
    });

    // On load, look up the existing value and give it human-friendly text if possible
    const currentValue = $element.val();
    if (currentValue && currentValue.trim().length > 0) {
      LocationPicker.doExactSearch(currentValue, (item) => {
        const description = ` (${_.escape(LocationPicker.getDescription(item))})`;
        const text = `${item.name}${description}`;
        self.richResultField.storeText(text);
        $element.data('item', item);
      });
    }
  }

  static getDescription(item) {
    if (item.parent && item.parent.name) {
      return `${item.parent.name}${(item.floor) ? `, ${item.floor}` : ''}`;
    }
    if (item.building) {
      return `${item.building}${(item.floor) ? `, ${item.floor}` : ''}`;
    }
    if (item.category && item.category.length > 0) {
      return item.category;
    }
    return '';
  }

  static transformItem(input) {
    const item = input;
    item.title = item.value;
    item.description = LocationPicker.getDescription(item);
    item.category = null;
  }

  doSearch(query, options, callback) {
    this.currentQuery = query;

    fetch(`https://campus-cms.warwick.ac.uk/api/v1/projects/1/autocomplete.json?term=${encodeURIComponent(query)}&exact_limit=2&limit=1000&substring=false&_ts=${new Date().getTime()}`, {
      method: 'GET',
      headers: {
        Authorization: campusMapAuthorizationHeader,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((e) => {
        log.error(e);
        return [];
      })
      .then((response) => {
        // Return the items only if the user hasn't since made a different query
        if (this.currentQuery === query) {
          $.each(response, (i, item) => LocationPicker.transformItem(item));
          callback(response || []);
        }
      });
  }

  static doExactSearch(id, callback) {
    fetch(`https://campus-cms.warwick.ac.uk/api/v1/projects/1/locations/${id}.json`, {
      method: 'GET',
      headers: {
        Authorization: campusMapAuthorizationHeader,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((e) => {
        log.error(e);
        return {};
      })
      .then((response) => {
        callback(response);
      });
  }
}

// The jQuery plugin
$.fn.locationPicker = function initLocationPicker(options = {}) {
  return this.each((i, element) => {
    const $this = $(element);
    if ($this.data('location-picker')) {
      throw new Error('LocationPicker has already been added to this element');
    }

    const allOptions = {
      selectOnBlur: $this.data('select-on-blur') !== false,
    };
    $.extend(allOptions, options);
    $this.data('location-picker', new LocationPicker(element, allOptions));
  });
};

export function bindTo($scope) {
  $('.location-picker', $scope).locationPicker();
}
